import { createRouter, createWebHistory } from 'vue-router';
import ApplicationFields from "@/pages/ApplicationFields";
import ApplicationAdd from "@/pages/ApplicationAdd";
import Application from "@/pages/Application";
import FieldsAdd from "@/pages/FieldsAdd";
import Documents from "@/pages/Documents";
import Values from "@/pages/Values";
import ServiceName from "@/pages/ServiceName";
import FieldCategories from "@/pages/FieldCategories";


const routes = [
  {
    path: '/application_fields',
    name: 'ApplicationFields',
    component: ApplicationFields
  },
  {
    path: '/application_add',
    name: 'ApplicationAdd',
    component: ApplicationAdd
  },
  {
    path: '/',
    name: 'Application',
    component: Application
  },
  {
    path: '/service_name',
    name: 'ServiceName',
    component: ServiceName
  },
  {
    path: '/field_categories',
    name: 'FieldCategories',
    component: FieldCategories
  },
  {
    path: '/fields_add',
    name: 'FieldsAdd',
    component: FieldsAdd
  },
  {
    path: '/values',
    name: 'Values',
    component: Values
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents
  },
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
});

export default router;
