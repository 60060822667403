<template>
  <li>{{ this.item }}</li>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "List",
  props: {
    item: []
  },
}
</script>

<style scoped>

</style>