import axios from 'axios';
// import LocalStorageService from '@/services/localStorageService';

// const { clearToken, getRefreshToken, setToken } =
//   LocalStorageService.installService();

// const DEFAULT_URL = 'https://tenderlandback.f-app.ru/';

// export const REFRESH_URL = '/users/refresh_token';
// export const baseURL = process.env.REACT_APP_API_URL || DEFAULT_URL;
// export const urlB = 'http://127.0.0.1:8000/';
// // export const urlB = 'https://promspetsservice.f-app.ru/';

export const apiInstance = axios.create({
    // baseURL : 'http://127.0.0.1:8000/',
    // baseURL : 'https://home.fiesta-lab.com/',
  baseURL : 'https://kr.arcsis.pro/',
  // baseURL : process.env.VUE_APP_API_URL,
  timeout : 1000000,
  auth: {
              username: 'admin',
              password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
              // eslint-disable-next-line no-return-assign
            },
  headers : { 'X-Custom-Header' : 'foobar' }
});

// let isRefreshing = false;
// let failedQueue = [];





// apiInstance.interceptors.response.use(
//   response => response,
//   error => errorHandler(error)
// );

export default apiInstance;

export const queryGet = (url, config = {}) => apiInstance.get(url, config);

export const queryPost = (url, data = null, config = {}) => apiInstance.post(url, data, config);

export const queryDelete = (url, config = {}) => apiInstance.delete(url, config);

export const queryPut = (url, data = null, config = {}) => apiInstance.put(url, data, config);