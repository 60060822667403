<template>
  <nav class="navbar navbar-expand-lg bg-light">
<!--  <div class="container-fluid">-->
<!--    <a class="navbar-brand" href="#">Navbar w/ text</a>-->
<!--    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">-->
<!--      <span class="navbar-toggler-icon"></span>-->
<!--    </button>-->
    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item  active">
          <button type="button" class="btn btn-outline-primary">
            <router-link to="/">Меры</router-link>
          </button>

        </li>
        <li class="nav-item">
          <button type="button" class="btn btn-outline-primary">
            <router-link to="/application_add">Создать заявку</router-link>
          </button>
        </li>
        <li class="nav-item">
          <button type="button" class="btn btn-outline-primary">
            <router-link to="/service_name">Справочник меры поддержки</router-link>
          </button>
        </li>
        <li class="nav-item">
          <button type="button" class="btn btn-outline-primary">
            <router-link to="/field_categories">Категории полей</router-link>
          </button>
        </li>
        <li class="nav-item">
          <button type="button" class="btn btn-outline-primary">
            <router-link to="/application_fields">Поля</router-link>
         </button>
        </li>
        <li class="nav-item">
          <button type="button" class="btn btn-outline-primary">
            <router-link to="/fields_add">Создать поля</router-link>
         </button>
        </li>

        <li class="nav-item">
          <button type="button" class="btn btn-outline-primary">
            <router-link to="/documents">Документы</router-link>
          </button>
        </li>
        <li class="nav-item">
          <button type="button" class="btn btn-outline-primary">
            <router-link to="/values">Значения Values</router-link>
          </button>
        </li>
      </ul>
    </div>
<!--  </div>-->
</nav>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Menu"
}
</script>

<style scoped>

</style>