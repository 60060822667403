import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'



createApp(App)
    .use(router)
    .mount('#app')
// Vue.config.productionTip = false
//
// /* eslint-disable no-new */
// new Vue({
// el: '#app',
// router: Router, // Router added to the Vue instance
// components: { App },
// template: '<App/>'
// })