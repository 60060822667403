<template>
<!--      {{field}}-->
      <tr>
        <th scope="row">{{field.id}}</th>
        <td>{{field.code}}</td>
        <td>{{field.name}}</td>
        <td>{{field.type}}</td>
        <td>{{field.required}}</td>
        <td>{{field.multiple}}</td>
        <td>{{field.multipleMinCount}}</td>
        <td>{{field.multipleMaxCount}}</td>
        <td>
          <ul v-if="show">
            <li v-for="item in field.valuess" :key="item.message">
              {{ item.value }}
            </li>
          </ul>
        </td>
        <td>{{field.validate_n}}</td>
        <td>{{field.validateDescription}}</td>
        <td>{{field.description}}</td>
        <td>{{field.defaultValue}}</td>
        <td>{{field.substitution}}</td>
        <td>{{field.sort}}</td>
        <td>
          <ul v-if="show">
            <li v-for="item in field.documentss" :key="item.message">
              {{ item }}
            </li>
          </ul></td>
        <td>{{field.fileExtensions}}</td>
        <td><button v-on:click="deleteField(field.id)" class="btn btn-primary" type="button">Удалить</button> {{this.res}}</td>
      </tr>




<!--    </div>-->
</template>

<script>
import {queryDelete} from "@/api/config";

export default {
  name: "ApplicationFieldItem",
  data () {
    return {
      res: null,
      showValues: true,
      showDocuments: true
    }
  },
  props: {
    field: null,
    show: null
  },
  methods: {
    async deleteField(id) {
      queryDelete(`/field/${id}`,
          {
            auth: {
              username: 'admin',
              password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
              // eslint-disable-next-line no-return-assign
            },
            headers: {
              'Content-Type': 'application/json'
            }
          }
          // eslint-disable-next-line no-return-assign
      ).then(response => (
          this.res = response.data))
          // eslint-disable-next-line no-return-assign
          .catch(response => (this.res = response));
    },
  }
}
</script>

<style scoped>

</style>