<template>
  <Menu/>
  <div class="card" style="width: 73rem;">

  <form @submit.prevent="Put"  class="mb-8" @keydown="form.onKeydown($event)">
   <input class="form-control" type="text" name="link" v-model="form.link" placeholder="ссылка">
   <input class="form-control" type="text" name="type" v-model="form.type" placeholder="Тип файла (его расширение: pdf, docx, jpg, …)">
   <input class="form-control" type="text" name="size" v-model="form.size" placeholder="Размер">
   <input class="form-control" type="text" name="name" v-model="form.name" placeholder="имя файла, отображаемое заявителю">
   <input class="form-control" type="text" name="description" v-model="form.description" placeholder="Описание">

    <button class="btn btn-primary">
      Создать
    </button> <p>{{ this.res }}</p>
  </form>
  </div>
<div class="row">
    <div class="col-md-4" v-for="item in documents" :key="item.id">
        <div class="card mb-3">
          <h5 class="card-header">{{ item.name }}</h5>
            <div class="card-body">
              {{item}}
              <p class="card-text">{{ item.description }}</p>
              <p class="card-text">ID: {{ item.id }} Размер: {{ item.size }} Тип: {{ item.type }}</p>
              <p class="card-text" style="font-size: smaller;">Ссылка: {{ item.link }}</p>
              <button class="btn btn-danger" @click="deleteDocument(item.id)">Удалить</button>
            </div>
        </div>
    </div>
</div>



</template>

<script>
import {queryDelete, queryGet, queryPost} from "@/api/config";
import Menu from "@/components/Menu";
import Form from "vform";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Documents",
  components: {Menu},
  data () {
    return {
      res: '',
      documents: [],
      componentKey: 0,
      form: new Form({
        link:  '',
        type:  '',
        size:  0,
        name:  '',
        description: ''
      }),
    }

  },
  async created() {
    await queryGet("document/")
      .then(response => (this.documents = response.data))
    console.log(this.documents)
    console.log(this.documents.length)
  },
  methods: {
    async deleteDocument(id) {
      queryDelete(`/document/${id}`,
          {
            auth: {
              username: 'admin',
              password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
              // eslint-disable-next-line no-return-assign
            },
            headers: {
              'Content-Type': 'application/json'
            }
          }
          // eslint-disable-next-line no-return-assign
      ).then(response => (
          this.res = response.data)).then(() => {this.documents = this.documents.filter(item => item.id !== id);})
          // eslint-disable-next-line no-return-assign
          .catch(response => (this.res = response));
    },
    async Put() {
      // const formData = new FormData();
      this.formData = {
        link: this.form.link,
        type: this.form.type,
        size: this.form.size,
        name: this.form.name,
        description: this.form.description
      };
      console.log(this.formData);
      queryPost(`document/`, this.formData,
          {
            // auth: {
            //   username: 'admin',
            //   password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
            //   // eslint-disable-next-line no-return-assign
            // },
            headers: {
              'Content-Type': 'application/json'
            }
          }
          // eslint-disable-next-line no-return-assign
      ).then(response => (this.res = response.data))
          // eslint-disable-next-line no-return-assign
          .catch(response => (this.res = response));
    },
  }
}
</script>

<style scoped>

</style>