<template>
  <div class="container" id="container" ref="infoBox">
    <Menu/>
    <List
    :item="item"
    v-for="item in serviceName.serviceName"
    :key="item.id"/>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import {queryGet} from "@/api/config";
import List from "@/components/List";

export default {
  name: "ServiceName",
  components: {Menu, List},
  data: () => ({
    serviceName: [],}),
  async created() {
    await queryGet("requests_to_msp/reference_lists")
      .then(response => (this.serviceName = response.data))
    console.log(this.serviceName)
  }


}
</script>

<style scoped>

</style>