<template>
  <div>
    <Menu/>
        <!-- Если данные загружаются, показываем значок загрузки -->
        <div v-if="isLoading">
          <!-- Загрузочное изображение -->
          <div class="spinner-grow" role="status">
            <span class="visually-hidden">Загрузка...</span>
          </div>

        </div>
    <button type="button" class="btn btn-outline-info" v-on:click="show = !show">Подробно</button>
    <br>
   <table class="table">
      <thead>
      <th >№</th>
      <th >Активна ли мера:</th>
      <th >Превью</th>
      <th >Дата </th>
      <th v-if="show">Короткое описание:</th>
      <th v-if="show">Полное описание: </th>
<!--      <th >serviceName</th>-->
<!--      <th >serviceType</th>-->
<!--      <th > sourceType</th>-->
      <th > Регламент срок предоставления меры</th>
      <th > Срок предоставления в днях</th>
      <!--      <th >Категории в мере</th>-->
      <th >Контакт ответственного </th>
      <th > Категория получателя</th>
      <th >Минимальная стоимость поддержки:</th>
      <th > Основание для отказа</th>
      <th > Основание для отказа</th>
      <th > Основание для отказа</th>
<!--      <th > Комплексной услуга</th>-->
      <th > Размер поддержки</th>
      <th >Документы:</th>
      <th v-if="show">Платная услуга</th>
      <th v-if="show">requirements</th>
      <th v-if="show"></th>
      </thead>
     <tbody>
        <ApplicationItem
          :support="support"
          v-for="support in support_measure"
          :key="support.id"
          :show="show"
        />
     </tbody>

    </table>

     <div>
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Previous" @click="prevPage" :disabled="currentPage === 1">
              <span class="sr-only">Предыдущая</span>
            </a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page">
            <a class="page-link" href="#" @click="goToPage(page)">{{ page }}</a>
          </li>
          <li></li>
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Next" @click="nextPage" :disabled="currentPage === totalPages">
              <span class="sr-only">Дальше</span>
            </a>
          </li><div>
          <li class="page-item">
            <span class="sr-only">Количество</span></li>
        </div>

          <li><select v-model="pageSize" @change="changePageSize" class="form-select" id="pageSize" aria-label="Default select example"><option value="5">5</option><option value="10">10</option><option value="20">20</option></select></li>

        </ul>
      </nav>
     </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import {queryGet} from "@/api/config";
import ApplicationItem from "@/components/ApplicationItem";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Application",
  components: {Menu, ApplicationItem},
  props: {
    field: null
  },
  data () {
    return {
      sortBy: 'id',
      isLoading: false, // Флаг для отображения значка загрузки
      sortDirection: 'asc',
      pageSize: 5, // Размер страницы
      currentPage: 1, // Номер текущей страницы
      totalPages: 0, // Общее количество страниц (получается из ответа сервера)
      page: 1,
      support_measure: [],
      show: false
    }
  },
  methods: {
    // Функция для перехода на предыдущую страницу
    async prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.fetchData();
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.fetchData();
      }
    },
    goToPage(page) {
        this.currentPage = page;
        this.fetchData();
    },

    changePageSize() {
      // Отправка запроса на сервер с новым значением pageSize
      this.fetchData();
    },
    async fetchData() {
      this.isLoading = true;
      const skip = (this.currentPage - 1) * this.pageSize; // Рассчитываем значение параметра skip на основе текущей страницы и размера страницы
      const limit = this.pageSize; // Размер страницы
      try {
        const response = await queryGet("application/", {
          auth: {
              username: 'admin',
              password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
              // eslint-disable-next-line no-return-assign
            },
            headers: {
              'Content-Type': 'application/json'
            },
          params: {
            skip: skip,
            limit: limit
          }
        });
        this.support_measure = response.data;
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.isLoading = false;
        // Обработка ошибок
      }
    }
  },
  async created() {
    // await queryGet("application/").then(response => (this.support_measure = response.data))
    const response = await queryGet("application/total_pages", {
          auth: {
              username: 'admin',
              password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
              // eslint-disable-next-line no-return-assign
            },
            headers: {
              'Content-Type': 'application/json'
            },
          params: {
            pageSize: this.pageSize,
          }
        });
        this.totalPages = response.data;

  },
  mounted() {
    // Вызов метода fetchData() при загрузке компонента для загрузки данных с сервера
    this.fetchData();
  }
}
</script>

<style scoped>
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Высота, равная высоте видимой области */
}
</style>