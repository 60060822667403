<template>
  <tr>
      <td >
        <span v-if="editableItem.id === localSupport.id && editableItem.field === 'idForm'">
          <input v-model="localSupport.idForm" @click.stop />
          <button @click.stop="saveEdit" type="button" class="btn btn-primary" style="--bs-btn-padding-y: .15rem; --bs-btn-padding-x: 1rem; --bs-btn-font-size: 1rem;">
            Сохранить
          </button>
          <button @click.stop="cancelEdit" type="button" class="btn btn-secondary" style="--bs-btn-padding-y: .15rem; --bs-btn-padding-x: 1rem; --bs-btn-font-size: 1rem;">
            Отмена
          </button>
        </span>
        <span v-else>
          <b>{{support.id_n}}</b><br>
          {{support.idForm}}<br>
          {{ localSupport.idForm }}
        </span>
      </td>
      <td >
        <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="isActive" v-on:click="update(support.id_n)" v-if="support.active" checked>
        <input class="form-check-input" type="checkbox" id="isActiveFalse" v-on:click="update(support.id_n)" v-if="!support.active">
<!--        <label class="form-check-label" for="flexSwitchCheckChecked">Выбранное поле флажок переключатель</label>-->
      </div></td>
      <td > <ui>{{support.preview}}</ui></td>
    <td > {{support.startDate}}<br>{{support.endDate}}</td>
    <td v-if="show"> <p >{{support.shortDescription}}</p></td>
    <td v-if="show"><p v-if="show">{{support.fullDescription}}</p></td>
    <!--      <td >{{support.serviceName}}</td>-->
    <!--      <td >{{support.serviceType}}</td>-->
    <!--      <td > {{support.sourceType}}</td>-->
    <td > {{support.provisionWaitPeriod}}</td>
    <td > {{support.provisionStartTime}}</td>
    <td > {{support.organizerContacts}}</td>
    <td > {{support.recipientCategory}}</td>
    <td > {{support.supportAmountPercent}}</td>
    <!--    <td > {{support.additionalRequirements}}</td>-->
    <!--    <td > {{support.preferentialRecipients}}</td>-->
    <td > {{support.offerDeclineReason}}</td>
    <td > {{support.supportEventName}}</td>
    <td > {{support.reviewDeclineReason}}</td>
    <!--    <td > {{support.recipientSpecialCategory}}</td>-->
    <td > {{support.fieldCategories}}</td>
<!--    <td > {{support.complexServiceInfo}}</td>-->
    <td v-if="show"><p v-if="show"> {{support.documents}}</p></td>
    <td v-if="show"> <p v-if="show"> {{support.supportCost}}</p></td>
    <td v-if="show">
        <p v-if="show">{{support.requirements}}</p>
      </td>
    <!--    <td v-if="show"> <p v-if="show"> {{support.supportAmount}}</p></td>-->
    <td > {{support.loanTerms}}</td>
    <td v-if="show"><button v-on:click="del(support.id_n)" class="btn btn-primary" type="button">Удалить</button> {{this.res}}</td>
  </tr>



</template>

<script>
import {queryPost} from "@/api/config";

export default {
  name: "ApplicationItem",

  props: {
    support: null,
    show: null
  },
  data () {
    return {
      localSupport: { ...this.support },
      res: null,
      isActive: false,
      editableItem: { id: null, field: null },
    }
  },
  methods: {
    toggleEditable(item, field) {
      if (this.editableItem.id === item.id && this.editableItem.field === field) {
        this.editableItem = { id: null, field: null };
      } else {
        this.editableItem = { id: item.id, field };
      }
    },
    saveEdit() {
      // Отправить запрос на сервер для сохранения изменений
      console.log('Save Edit:', this.localSupport);
      this.editableItem = { id: null, field: null };
    },
    cancelEdit() {
      // Вернуть исходные данные при отмене
      this.editableItem = { id: null, field: null };
      this.localSupport = { ...this.support };
    },
    async del(id) {
      queryPost(`application/del?id=${id}`, id,
          {
            auth: {
              username: 'admin',
              password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
              // eslint-disable-next-line no-return-assign
            },
            headers: {
              'Content-Type': 'application/json'
            }
          }
          // eslint-disable-next-line no-return-assign
      ).then(response => (this.res = response.data))
          // eslint-disable-next-line no-return-assign
          .catch(response => (this.res = response));

    },
    async update(id) {
      console.log(id)
      queryPost(`application/update?id=${id}`, id, {
        auth: {
          username: 'admin',
          password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error(error);
          if (error.response.status === 404) {
            alert('Application not found');
          } else {
            alert('An error occurred');
            }
        });

    }
  }
}
</script>

<style scoped>

</style>