<template>
  <Menu/>
  <div class="card" style="width: 73rem;">

  <form @submit.prevent="Put"  class="mb-8" @keydown="form.onKeydown($event)">
   <input class="form-control" type="text" name="link" v-model="form.id" placeholder="id">
   <input class="form-control" type="text" name="type" v-model="form.value" placeholder="Значение">

    <button class="btn btn-primary">
      Создать
    </button> <p>{{ this.res }}</p>
  </form>
  </div>
  <List
      :item="item"
      v-for="item in values"
      :key="item.id"
    />
</template>

<script>
import {queryGet, queryPost} from "@/api/config";
import Menu from "@/components/Menu";
import Form from "vform";
import List from "@/components/List";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Values",
  components: {Menu, List},
  data () {
    return {
      res: '',
      values: [],
      form: new Form({
        id:  0,
        value:  ''
      }),
    }

  },
  async created() {
    await queryGet("values/")
      .then(response => (this.values = response.data))
    console.log(this.values)
    console.log(this.values.length)
  },
  methods: {
    async Put() {
      // const formData = new FormData();
      this.formData = {
        id: this.form.id,
        value: this.form.value,
      };
      console.log(this.formData);
      queryPost(`values/`, this.formData,
          {
            // auth: {
            //   username: 'admin',
            //   password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
            //   // eslint-disable-next-line no-return-assign
            // },
            headers: {
              'Content-Type': 'application/json'
            }
          }
          // eslint-disable-next-line no-return-assign
      ).then(response => (this.res = response.data))
          // eslint-disable-next-line no-return-assign
          .catch(response => (this.res = response));
    },
  }
}
</script>

<style scoped>

</style>