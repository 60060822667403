<template>
  <option selected>{{ option }}</option>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Option",
  props: {
    option: []
  },
}
</script>

<style scoped>

</style>