<template>
  <div>
    <Menu/>
    <br>
    <table class="table">
      <thead>
        <th scope="col">№</th>
        <th scope="col">Код поля в Битриксксе<br></th>
        <th scope="col">Заголовок поля</th>
        <th scope="col">Тип поля</th>
        <th scope="col">Обязательность заполнения</th>
        <th scope="col">Множественность поля</th>
        <th scope="col">Минимальное количество</th>
        <th scope="col">Максимальное количество</th>
        <th scope="col">Какие значения могут принимать</th>
        <th scope="col">Регулярное выражение</th>
        <th scope="col">Словесное описание формата заполнения поля</th>
        <th scope="col">Краткое описание поля</th>
        <th scope="col">Значение поля по умолчанию</th>
        <th scope="col">Код подстановки из справочника</th>
        <th scope="col">Индекс сортировки</th>
        <th scope="col">Документы</th>
        <th scope="col">Раширеня файлов</th>

      </thead>
      <tbody>
      <ApplicationFieldItem
          :field="field"
          v-for="field in fields"
          :key="field.id"
          :show="show"
      />
      </tbody>
    </table>
    <!--    {{ this.fields }}-->
  </div>

</template>

<script>
import {queryGet} from "@/api/config";
import Menu from "@/components/Menu";
import ApplicationFieldItem from "@/components/ApplicationFieldItem";

export default {
  name: "ApplicationFields",
  components: {ApplicationFieldItem, Menu},
  props: {

  },
  data () {
    return {
      fields: [],
      show: false
    }
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  },
  async created() {
    await queryGet("/field/all")
      .then(response => (this.fields = response.data))
    console.log(this.fields)

  }
}
</script>

<style scoped>

</style>