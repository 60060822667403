<template>
  <div>
    <Menu/>
        <!-- Инпут для ввода ID элемента -->
    <div class="input-group mb-3">
      <input type="number" v-model="itemId" placeholder="Введите ID меры для редактирования">
      <button class="btn btn-outline-secondary" type="button" @click="getItemById">Получить элемент</button>
    </div>
    <div v-if="isLoading">
      <!-- Загрузочное изображение -->
      <div class="spinner-grow" role="status">
      <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>

 <div class="card" @keydown="form.onKeydown($event)">

  <form @submit.prevent="Put"  class="mb-8">
    <p class="h6">Идентификатор меры поддержки в ОИП<span class="required">*</span></p>
   <input class="form-control" type="text" name="id" v-model="form.id" placeholder="id" required>
    <p class="h6">Идентификатор формы предоставления меры поддержки в зависимости от категории Заявителя (recipient)<span class="required">*</span></p>
   <input class="form-control" type="text" name="idForm" v-model="form.idForm" placeholder="" required>

    <input class="form-check-input" type="checkbox" value="" v-model="form.active" id="flexactive">
    <label class="form-check-label" for="flexactive" >
    флаг активности меры поддержки <br>
    </label>
    <br>
    <label>Наименование меры поддержки<span class="required">*</span></label>
    <input class="form-control" type="text" name="name" v-model="form.name" placeholder="Введите текст (максимум 150 символов)" maxlength="150" required>

    <label>Краткое описание детальной страницы меры поддержки (preview)<span class="required">*</span></label>
    <textarea id="preview" name="preview" class="form-control" v-model="form.preview" placeholder="Введите текст (максимум 250 символов)" maxlength="250" required></textarea>

    <label>Краткое описание детальной страницы меры поддержки<span class="required">*</span></label>
    <textarea class="form-control" name="shortDescription" v-model="form.shortDescription" placeholder="Введите краткое описание (максимум 300 символов)" maxlength="300" required></textarea>

    <label>Полное, подробное описание меры поддержки</label>
    <textarea id="full" class="form-control" type="text" name="fullDescription" v-model="form.fullDescription" placeholder="Полное, подробное описание меры поддержк" maxlength="1000"></textarea>

    <label for="startDate">Начало оказания услуги<span class="required">*</span></label>
    <!-- Отдельное поле для даты -->
    <input class="form-control" type="date" id="startDate" name="startDate" v-model="form.startDate" required>
    <!-- Отдельное поле для времени -->
    <input class="form-control" type="time" id="startTime" name="startTime" v-model="form.startTime" required>

    <label for="endDate">Конец оказания услуги<span class="required">*</span></label>

    <!-- Отдельное поле для даты -->
    <input class="form-control" type="date" id="endDate" name="endDate" v-model="form.endDate" required>
    <!-- Отдельное поле для времени с значением по умолчанию -->
    <input class="form-control" type="time" id="endTime" name="endTime" v-model="form.endTime" required>


     <p class="h6">Доступность меры поддержки по объему предоставляемой поддержки. При наличии указывается количественный показатель (лимит) оказания меры поддержки (например, возможно оказать только 33 консультации одному субъекту МСП). Указывается именно лимит на 1 заявителя именно в ЕДИНИЦАХ (ни в рублях, ни в метрах).</p>
    <input class="form-control" type="number" name="supportCount" v-model="form.supportCount" placeholder="0" required>

    <label for="opf">Требования к заявителю для осуществления проактивного подбора мер поддержки<span class="required">*</span></label>
    <select size=5 class="form-control" id="opf" name="opf" v-model="form.opf" multiple required>
        <option value="individual" selected>ИП (Индивидуальный предприниматель)</option>
        <option value="legal" selected>Юр. лицо (Юридическое лицо)</option>
        <option value="physical">Физ. лицо (Физическое лицо)</option>
        <option value="self">Самозанятый</option>
        <option value="selfindividual">Самозанятый ИП (Самозанятый Индивидуальный предприниматель)</option>
    </select>

    <br><br>

     <p class="h6">Для Заявителя на ЦП МСП данная информация будет отображаться в виде «от 60 тыс. руб.»</p>
    <input class="form-control" type="text" name="supportAmountFrom" v-model="form.supportAmountFrom" placeholder="Nane">

     <p class="h6">Указывается максимальный размер поддержки в рублях, если применимо</p>
    <input class="form-control" type="text" name="supportAmountTill" v-model="form.supportAmountTill" placeholder="Nane">



    <p class="h6">Массив документов и нормативно-правовых актов</p>
    <select class="form-select" size="{{this.documents.length}}" v-model="form.documents" aria-label="documents"  multiple>
      <option v-for="option in documents" :key="option.id" :value="JSON.stringify(option)"><b>{{ option.name }}</b> {{option}}</option>
    </select>


    <p class="h6">Категории полей в мере поддержки</p>
    <select class="form-select" size="{{this.categories.length}}" v-model="form.categories" aria-label="categories"  multiple>
      <option v-for="option in categories" :key="option.id" :value="JSON.stringify(option)">{{ option }}</option>
    </select>

    <br>

    <label>Наименование меры поддержки из справочника<span class="required">*</span></label>
    <select class="form-select" size="{{this.serviceNameList.length}}" v-model="this.form.serviceName" aria-label="Service" required>
      <option v-for="service in serviceNameList.serviceName" :value="service.CODE" :key="service.id">{{ service.NAME }}</option>
    </select>

     <label class="h6">Форма поддержки Необходимо подставить справочное значение в соответствии с требованиями<span class="required">*</span></label>
    <select class="form-select" size="{{this.serviceNameList.length}}" v-model="this.form.supportType" aria-label="supportType" required>
      <option v-for="service in serviceNameList.supportType" :value="service.CODE" :key="service.id">{{ service.NAME }}</option>
    </select>

     <label class="h6">Вид/тип меры поддержки<span class="required">*</span></label>
    <select class="form-select" size="{{this.serviceNameList.length}}" v-model="this.form.serviceType" aria-label="serviceType" required>
      <option v-for="service in serviceNameList.serviceType" :value="service.CODE" :key="service.id">{{ service.NAME }}</option>
    </select>

     <label>Наименование мероприятия, направленного на предоставление меры поддержки<span class="required">*</span></label>
    <select class="form-select" size="{{this.serviceNameList.length}}" v-model="this.form.supportEventName" aria-label="supportEventName" required>
      <option v-for="service in serviceNameList.supportEventName" :value="service.CODE" :key="service.id">{{ service.NAME }}</option>
    </select>

     <p class="h6">Информации структурного подразделения организации, образующей инфраструктуру поддержки субъектов малого и среднего предпринимательства, предоставляющего меру поддержки</p>
     <p class="h6">Телефон</p>
    <input class="form-control" type="text" name="organizerContactsPhone" v-model="form.organizerContactsPhone" placeholder="79053868***" required>
     <p class="h6">e-mail</p>
    <input class="form-control" type="text" name="organizerContactsEmail" v-model="form.organizerContactsEmail" placeholder="79053868***@ya.ru" required>
    <br>

     <p class="h6">Способ обращения за мерой поддержки</p>
    <input class="form-control" type="text" name="sourceType" v-model="form.sourceType" placeholder="None">

     <label>Основание для отказа в рассмотрении отправленной Заявки на получение меры поддержки<span class="required">*</span></label>
    <input class="form-control" type="text" name="reviewDeclineReason" v-model="form.reviewDeclineReason" placeholder="Некорректное заполнение обязательных полей в форме заявления на Цифровой платформе МСП" required>

     <label>Основание для отказа в предоставлении меры поддержки<span class="required">*</span></label>
    <input class="form-control" type="text" name="offerDeclineReason" v-model="form.offerDeclineReason" placeholder="None">

     <p class="h6">Сведения о включении меры поддержки в состав комплексной услуги</p>
    <input class="form-control" type="number" name="complexServiceInfo" v-model="form.complexServiceInfo" placeholder="0">

     <p class="h6">Размер поддержки на одного получателя меры поддержки  указать размер поддержки на одного получателя меры поддержки в процентах</p>
    <input class="form-control" type="text" name="supportAmountPercent" v-model="form.supportAmountPercent" placeholder="None">

    <br>
     <p class="h6">Стоимость получения меры поддержки</p>
    <input class="form-check-input" type="checkbox" value="" v-model="form.isPaid" id="isPaid">
    <label class="form-check-label" for="flexactive" >
    Платная/бесплатная <br>
    </label>
    <input class="form-control" type="number" name="minCost" v-model="form.minCost" placeholder="">
    <input class="form-control" type="number" name="maxCost" v-model="form.maxCost" placeholder="">
    <br><br>

     <p class="h6">Регламентный срок предоставления меры поддержки/принятия решения после предоставления полного пакета документов, в днях</p>
    <input class="form-control" type="number" name="provisionWaitPeriod" v-model="form.provisionWaitPeriod" placeholder="0">

     <p class="h6">Срок предоставления меры поддержки, в днях</p>
    <input class="form-control" type="number" name="provisionStartTime" v-model="form.provisionStartTime" placeholder="0">

     <p class="h6">Категория получателя меры поддержки small  medium  other</p>
    <input class="form-control" type="text" name="recipientCategory" v-model="form.recipientCategory" placeholder="micro, small" required>

    <label>Поля которые относятся к мере<span class="required">*</span></label>
    <select class="form-select" :size="this.fields.length" v-model="this.form.fields" aria-label="fields" multiple required>
      <option v-for="field in fields" :key="field.id" :value="JSON.stringify(field)">{{ field }}</option>
    </select>


    <button class="btn btn-primary">
      Создать
    </button>
     <div v-if="isLoading">
      <!-- Загрузочное изображение -->
      <div class="spinner-grow" role="status">
      <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
    <p>{{ this.res }}</p>
  </form>
 </div>
    </div>
</template>

<script>
import Menu from "@/components/Menu";
import Form from 'vform';
import {queryGet, queryPost} from "@/api/config";
import Option from "@/components/Option";

export default {
  name: "ApplicationAdd",
  // eslint-disable-next-line vue/no-unused-components
  components: {Menu, Option},
  data: () => ({
    isLoading: false,
    res: null,
    itemId: null, // Переменная для хранения ID элемента из инпута
    item: [], // Переменная для хранения полученного элемента
    documents: [],
    serviceNameList: [],
    selectedServiceName: '',
    selectedField: '',
    categories: null,
    fields: [],
    form: new Form({
          id: '',
          idForm: '',
          active: true,
          name: '',
          preview: '',
          shortDescription: '',
          startDate: '',
          startTime: '00:00',
          endDate: '',
          endTime: '00:00',
          supportType: null,
          supportCount: 0,
          opf: 'individual, legal',
          supportAmountFrom: '',
          supportAmountTill: '',
          fullDescription: null,
          documents: null,
          categories: null,
          serviceName: null,
          serviceType: null,
          organizerContactsPhone: null,
          organizerContactsEmail: null,
          sourceType: null,
          supportEventName: null,
          complexServiceInfo: 0,
          reviewDeclineReason: "Некорректное заполнение обязательных полей в форме заявления на Цифровой платформе МСП",
          offerDeclineReason: "Заявитель не соответствует установленным требованиям к получателям поддержки",
          supportAmountPercent: null,
          isPaid: false,
          minCost: 0,
          maxCost: 0,
          provisionWaitPeriod: 0,
          provisionStartTime: 0,
          recipientCategory: "micro, small",
          fields: [],
    }),
  }),
  async created() {
    this.isLoading = true;
    await queryGet("document/")
        .then(response => {
            this.documents = response.data.map(doc => doc.name);
        });
    await queryGet("/field/all")
      .then(response => (
      this.fields = response.data.map(cat => cat.name)))
    console.log(this.fields)
    await queryGet("categories/")
      .then(response => (
          this.categories = response.data.map(cat => cat.value)))
    await queryGet("requests_to_msp/reference_lists_all")
      .then(response => (this.serviceNameList = response.data))
    console.log(this.fields);
    this.isLoading = false;
    // console.log(this.serviceName)
    // console.log(this.code.length)
  },
  methods: {
      async getItemById() {
        this.isLoading = true;
        // Проверка, что itemId не пустой
        console.log(this.itemId);
        // Отправка запроса на сервер для получения элемента по ID
        const response = await queryGet(`application/${this.itemId}`);
        this.item = response.data; // Сохранение полученного элемента
        // this.item.
        // this.item.field = field.map(value => JSON.stringify(value));

        this.form = new Form(this.item.application); // Заполнение формы полученными данными
        this.form.fields = this.item.application.fields_a.map(value => value.name).map(name => JSON.stringify(name));
        this.form.categories = this.item.application.categoriess.map(value => value.value).map(value => JSON.stringify(value));
        console.log(this.item.application.documentss)
        this.form.documents = this.item.application.documentss.map(value => value.name).map(value => JSON.stringify(value));
        this.form.organizerContactsPhone = this.item.application.organizerContacts.phone;
        this.form.organizerContactsEmail = this.item.application.organizerContacts.email;
        this.form.isPaid = this.item.application.supportCost.isPaid;
        this.form.minCost = this.item.application.supportCost.minCost;
        this.form.maxCost = this.item.application.supportCost.maxCost;
        this.form.serviceName = this.item.application.serviceName;
        this.form.supportType = this.item.application.supportType;
        this.form.serviceType = this.item.application.serviceType;
        this.form.supportEventName = this.item.application.supportEventName;


        function formattedDateTime(startDate) {
            console.log(startDate)
            // Парсим дату из строки
            const date = new Date(startDate.replace('T ', 'T'));
            console.log(date)
            // Получаем день, месяц и год
            const day = date.getDate().toString().padStart(2, '0');
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const year = date.getFullYear();
            // Получаем часы и минуты
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // Формируем строку с датой и временем в нужном формате
            const formattedDate = `${year}-${month}-${day}`;
            console.log(formattedDate)
            const formattedTime = `${hours}:${minutes}`;
            console.log(formattedTime)
            return [formattedDate, formattedTime];
        }

        const [formattedDateStart, formattedTimeStart] = formattedDateTime(this.item.application.startDate);
        this.form.startDate = formattedDateStart;
        this.form.startTime = formattedTimeStart;
        const [formattedDateEnd, formattedTimeEnd] = formattedDateTime(this.item.application.endDate);
        this.form.endDate = formattedDateEnd;
        this.form.endTime = formattedTimeEnd;
        if (this.item.application.requirements.opf) {
          console.log(this.item.application.requirements.opf)
          this.form.opf = this.item.application.requirements.opf.split(", ").filter(Boolean);
        }
        // this.form.serviceName = JSON.parse(this.item.application.serviceName);
        // this.form.supportType = JSON.parse(this.item.application.supportType);
        // this.form.serviceType = JSON.parse(this.item.application.serviceType);
        // this.form.supportEventName = JSON.parse(this.item.application.supportEventName);
        // this.selectedServiceName = { NAME: this.form.serviceName, CODE: this.form.serviceType }; // Заполнение выбранной услугой
        this.isLoading = false;
        this.isLoading = false;
      },

      combinedDateTime() {
        return `${this.form.startDate}T${this.form.startTime}`;
      },
      combinedDateTimeEnd() {
        return `${this.form.endDate}T${this.form.endTime}`;
      },

      async Put() {
      console.log(this.form.opf)
      if (this.form.opf && Array.isArray(this.form.opf)) {
          this.form.opf = this.form.opf.join(', ');
      }
      function formatDate(date) {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          const hours = String(date.getHours()).padStart(2, '0');
          const minutes = String(date.getMinutes()).padStart(2, '0');
          const seconds = String(date.getSeconds()).padStart(2, '0');
          const timezoneOffset = -date.getTimezoneOffset() / 60; // Приводим к часам с учетом смещения времени
          const timezoneOffsetString = timezoneOffset >= 0 ? `+${String(timezoneOffset).padStart(2, '0')}:00` : `${String(timezoneOffset).padStart(3, '0')}:00`;

          return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffsetString}`;
      }
      // function jsonToString(obj) {
      //   return JSON.stringify(obj);
      // }
      this.isLoading = true;
      // const formData = new FormData();
      if (this.itemId) {
        this.isLoading = true;
        this.formData = {
          id_n: this.itemId,
          id: this.form.id,
          idForm: this.form.idForm,
          active: this.form.active,
          name: this.form.name,
          preview: this.form.preview,
          shortDescription: this.form.shortDescription,
          startDate: formatDate(new Date(this.combinedDateTime())),
          endDate: formatDate(new Date(this.combinedDateTimeEnd())),
          supportType: this.form.supportType,
          supportCount: this.form.supportCount,
          opf: this.form.opf,
          supportAmountFrom: this.form.supportAmountFrom,
          supportAmountTill: this.form.supportAmountTill,
          fullDescription: this.form.fullDescription,
          documents: this.form.documents,
          fieldCategories: this.form.categories,
          serviceName: this.form.serviceName,
          serviceType: this.form.serviceType,
          organizerContactsPhone: this.form.organizerContactsPhone,
          organizerContactsEmail: this.form.organizerContactsEmail,
          sourceType: this.form.sourceType,
          supportEventName: this.form.supportEventName,
          complexServiceInfo: this.form.complexServiceInfo,
          reviewDeclineReason: this.form.reviewDeclineReason,
          offerDeclineReason: this.form.offerDeclineReason,
          supportAmountPercent: this.form.supportAmountPercent,
          isPaid: this.form.isPaid,
          minCost: this.form.minCost,
          maxCost: this.form.maxCost,
          provisionWaitPeriod: this.form.provisionWaitPeriod,
          provisionStartTime: this.form.provisionStartTime,
          recipientCategory: this.form.recipientCategory,
          fields: this.form.fields,
      };
        this.res = null;
        queryPost(`application/`, this.formData, {
              auth: {
                username: 'admin',
                password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
                // eslint-disable-next-line no-return-assign
              },
              headers: {
                'Content-Type': 'application/json'
              }
            })
          .then(response => {
            this.res = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            this.res = error;
            this.isLoading = false;
          });
      }
      else {
      this.formData = {
          id: this.form.id,
          idForm: this.form.idForm,
          active: this.form.active,
          name: this.form.name,
          preview: this.form.preview,
          shortDescription: this.form.shortDescription,
          startDate: new Date(this.combinedDateTime()).toString(),
          endDate: new Date(this.combinedDateTimeEnd()).toString(),
          supportType: this.form.supportType,
          supportCount: this.form.supportCount,
          opf: this.form.opf,
          supportAmountFrom: this.form.supportAmountFrom,
          supportAmountTill: this.form.supportAmountTill,
          fullDescription: this.form.fullDescription,
          documents: this.form.documents,
          fieldCategories: this.form.categories,
          serviceName: this.form.serviceName,
          serviceType: this.form.serviceType,
          organizerContactsPhone: this.form.organizerContactsPhone,
          organizerContactsEmail: this.form.organizerContactsEmail,
          sourceType: this.form.sourceType,
          supportEventName: this.form.supportEventName,
          complexServiceInfo: this.form.complexServiceInfo,
          reviewDeclineReason: this.form.reviewDeclineReason,
          offerDeclineReason: this.form.offerDeclineReason,
          supportAmountPercent: this.form.supportAmountPercent,
          isPaid: this.form.isPaid,
          minCost: this.form.minCost,
          maxCost: this.form.maxCost,
          provisionWaitPeriod: this.form.provisionWaitPeriod,
          provisionStartTime: this.form.provisionStartTime,
          recipientCategory: this.form.recipientCategory,
          fields: this.form.fields,
      };
      console.log(this.formData);
      queryPost(`application/`, this.formData,
          {
            auth: {
              username: 'admin',
              password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
              // eslint-disable-next-line no-return-assign
            },
            headers: {
              'Content-Type': 'application/json'
            }
          }
          // eslint-disable-next-line no-return-assign
      ).then(response => {
          this.res = response.data;
          this.isLoading = false;
      })
          // eslint-disable-next-line no-return-assign
          .catch(response => {
            this.res = response;
            this.isLoading = false;
          });
    }
      }
  }
}
</script>

<style scoped>
#full{
  width: 100%;
  height: 220px;
}
.required {
    color: red;
}
</style>