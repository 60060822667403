<template>
  <Menu/>
  <div v-if="isLoading">
    <!-- Загрузочное изображение -->
    <div class="spinner-grow" role="status">
    <span class="visually-hidden">Загрузка...</span>
    </div>
  </div>
  <div class="card"  @keydown="form.onKeydown($event)">
          <!-- Инпут для ввода ID элемента -->
    <div class="input-group mb-3">
      <input type="number" v-model="itemId" placeholder="Введите ID меры для редактирования">
      <button class="btn btn-outline-secondary" type="button" @click="getItemById">Получить элемент</button>
    </div>

  <form @submit.prevent="Put"  class="mb-8">
    <p class="h6">активна (1), не активна (0)<span class="required">*</span></p>
   <input class="form-control" type="number" name="active" v-model="form.active" placeholder="active" required>
    <p class="h6">Код поля в Битрикс 24<span class="required">*</span></p>
   <input class="form-control" type="text" name="code" v-model="form.code" placeholder="">
     <p class="h6">Заголовок поля, он же заголовок поля формы<span class="required">*</span></p>
    <input class="form-control" type="text" name="name" v-model="form.name" placeholder="name" required>


    <p class="h6">Категории полей в мере поддержки</p>
    <select class="form-select" size="{{this.categories.length}}" v-model="form.categories" aria-label="categories"  multiple>
      <option v-for="option in categories" :key="option.id" :value="JSON.stringify(option)">{{ option }}</option>
    </select>


    <p class="h6">Тип поля<span class="required">*</span></p>
    <select class="form-select" v-model="form.type" size="{{this.reference.length}}" aria-label="type" required>
       <option selected>int</option>
       <option selected>string</option>
       <option selected>address</option>
       <option selected>date</option>
       <option selected>select</option>
       <option selected>selectAjax</option>
       <option selected>checkbox</option>
       <option selected>email</option>
       <option selected>phone</option>
       <option selected>text</option>
       <option selected>fileLink</option>
       <option selected>fileBase</option>
       <option selected>okwed</option>
       <option selected>complex</option>
    </select>

    <input class="form-check-input" type="checkbox" value="0" v-model="form.required" id="flexCheckrequired">
    <label class="form-check-label" for="flexCheckrequired" >
    обязательность поля: true/false
    </label>
    <br>
    <input class="form-check-input" type="checkbox" value="0" v-model="form.multiple" id="flexCheckmultiple">
    <label class="form-check-label" for="flexCheckmultiple">
    Множественность результата (приемка массивом): true/false
    </label>

    <p class="h6">Для множественного поля минимальное количество выбранных вариантов, для множественного поля типа complex - минимальное количество наборов подполей</p>
    <input class="form-control" type="number" name="multipleMinCount" v-model="form.multipleMinCount" placeholder="Nane">
<!--    <div v-if="form.errors.has('brand')" v-html="form.errors.get('brand')" /><br>-->
    <p class="h6">Для множественного поля максимальное количество выбранных вариантов, для множественного поля типа complex - максимальное количество наборов подполей</p>
    <input class="form-control" type="number" name="multipleMaxCount" v-model="form.multipleMaxCount" placeholder="Nane">
<!--    <div v-if="form.errors.has('brand')" v-html="form.errors.get('brand')" /><br>-->

    <p class="h6">
    Значения checkbox, select,
    где id - значение, которое принимается ОИП,
    value - значение, выводимое заявителю в форме (до 250 символов),
    если values пуст, а тип выбран checkbox или select,
    заполняется вариантами по аналогии с заполнением его</p>
    <select class="form-select" size="{{this.values.length}}" aria-label="values" v-model="form.values" multiple>
      <option v-for="option in values" :key="option.id" :value="JSON.stringify(option)">{{ option }}</option>
    </select>

    <p class="h6">Регулярное выражение для проверки корректности заполнения поля, соответствующее требованиям php, корректный формат можно составить и проверить на сайте https://www.phpliveregex.com</p>
    <input class="form-control" type="text" name="validate" v-model="form.validate" placeholder="str">
<!--    <div v-if="form.errors.has('brand')" v-html="form.errors.get('brand')" /><br>-->
    <p class="h6">Словесное описание ожидаемого формата заполнения поля в заявке</p>
    <input class="form-control" type="text" name="validateDescription" v-model="form.validateDescription" placeholder="str">
<!--    <div v-if="form.errors.has('brand')" v-html="form.errors.get('brand')" /><br>-->
    <p class="h6">краткое описание поля<span class="required">*</span></p>
    <input class="form-control" type="text" name="description" v-model="form.description" placeholder="str" required>


    <p class="h6">Значение поля по умолчанию</p>
    <input class="form-control" type="text" name="defaultValue" v-model="form.defaultValue" placeholder="Nane">

    <p class="h6">Код подстановки из справочника, полученного по API, для подстановки в формах МСП</p>
    <select class="form-select" size="{{this.reference.length}}" v-model="form.substitution" aria-label="substitution">
      <option v-for="option in reference" :key="option.id" :value="option.CODE">{{ option.NAME }}</option>
    </select>

    <p class="h6">Индекс сортировки поля в форме (чем меньше числовое значение, тем выше будет находиться поле) <span class="required">*</span></p>
    <input class="form-control" type="number" name="sort" v-model="form.sort" placeholder="" required>

    <p class="h6">Документы</p>
    <select class="form-select" size="{{this.documents.length}}" v-model="form.documents" aria-label="documents"  multiple>
      <option v-for="option in documents" :key="option.id" :value="JSON.stringify(option)"><b>{{ option.name }}</b> {{option}}</option>
    </select>

    <p class="h6">Допустимые расширения файлов для полей типа fileLink</p>
    <input class="form-control" type="text" name="fileExtensions" v-model="form.fileExtensions" placeholder="">
<!--    <div v-if="form.errors.has('brand')" v-html="form.errors.get('brand')" /><br>-->
    <p class="h6">Массив кодов (code) по умолчанию множественных полей меры поддержки, используемых в рамках комплексного поля.</p>
    <select class="form-select" size="27" v-model="form.complexFields" aria-label="complexFields" multiple>
      <option v-for="option in complexFields" :key="option.id" :value="option">{{ option }}</option>
    </select>
    <br><br>
    <button class="btn btn-primary">
      Создать
    </button>
    <div v-if="isLoading">
      <!-- Загрузочное изображение -->
      <div class="spinner-grow" role="status">
      <span class="visually-hidden">Загрузка...</span>
      </div>
    </div>
    <p>{{ res }}</p>

  </form>
  </div>
</template>

<script>
import {queryGet, queryPost} from "@/api/config";
import Menu from "@/components/Menu";
import Form from "vform";

export default {
  name: "FieldsAdd",
  components: {Menu},
  data () {
    return {
      res: null,
      isLoading: true,
      code_length: 50,
      complexFields: [],
      itemId: null, // Переменная для хранения ID элемента из инпута
      item: null, // Переменная для хранения полученного элемента
      values: [],
      values_form: [],
      parseDocuments: [],
      foundDocument: [],
      reference: [],
      categories: null,
      documents: [],
      form: new Form({
        active:  1,
        code:  null,
        name:  null,
        categories:  null,
        type:  null,
        required:  false,
        multiple: false,
        multipleMinCount: null,
        multipleMaxCount: null,
        values: [],
        validate: null,
        validateDescription: null,
        description: null,
        defaultValue: null,
        substitution: null,
        sort: null,
        documents: null,
        fileExtensions: null,
        complexFields: null,
      }),
    }

  },
  async created() {
    await queryGet("requests_to_msp/field_reference")
      .then(response => (this.reference = response.data))
    await queryGet("values/")
      .then(response => (
          this.values = response.data.map(cat => cat.value)))
    await queryGet("categories/")
      .then(response => (
          this.categories = response.data.map(cat => cat.value)))
    await queryGet("document/")
        .then(response => {
            this.documents = response.data.map(doc => doc.name);
        });
    await queryGet("field/all")
      .then(response => (this.complexFields = response.data.map(cat => cat.name)))
    this.isLoading = false;
    console.log(this.reference)
    console.log(this.reference.length)
  },
  methods: {

    async getItemById() {
        // Проверка, что itemId не пустой
      this.isLoading = true;
        console.log(this.itemId);
        // Отправка запроса на сервер для получения элемента по ID
        const response = await queryGet(`field/${this.itemId}`);
      this.item = response.data; // Сохранение полученного элемента
      // this.res = response.data; // Сохранение полученного элемента
      const categor = this.item.field.categoriess.map(doc => doc.value);
      this.item.field.categories = categor.map(value => JSON.stringify(value));
      const names = this.item.field.documentss.map(doc => doc.name);
      this.item.field.documents = names.map(name => JSON.stringify(name));
      const val = this.item.field.valuess.map(doc => doc.value);
      this.item.field.values = val.map(value => JSON.stringify(value));
      const codes = this.item.field.complex_fields;
      this.item.field.complexFields = codes.map(item => item.name);

      this.form = this.item.field; // Заполнение формы полученными данными
      this.isLoading = false;
      },
    async Put() {
      // const formData = new FormData();
      this.isLoading = true;
      if (this.itemId) {
        console.log(this.form.values);
        this.formData = {
          id: this.itemId,
          name: this.form.name,
          active: this.form.active,
          code: this.form.code,
          categories: this.form.categories,
          type: this.form.type,
          required: this.form.required,
          multiple: this.form.multiple,
          multipleMinCount: this.form.multipleMinCount,
          multipleMaxCount: this.form.multipleMaxCount,
          values: this.form.values,
          validate: this.form.validate,
          validateDescription: this.form.validateDescription,
          description: this.form.description,
          defaultValue: this.form.defaultValue,
          substitution: this.form.substitution,
          sort: this.form.sort,
          documents: this.form.documents,
          fileExtensions: this.form.fileExtensions,
          complexFields: this.form.complexFields,
        };
        console.log(this.form.values);
        console.log(this.formData);
        this.res = null;
        queryPost(`field/`, this.formData, {
              auth: {
                username: 'admin',
                password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
                // eslint-disable-next-line no-return-assign
              },
              headers: {
                'Content-Type': 'application/json'
              }
            })
          .then(response => {
            this.res = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            this.res = error;
            this.isLoading = false;
          });
      }
      else {
        this.formData = {
          name: this.form.name,
          active: this.form.active,
          code: this.form.code,
          categories: this.form.categories,
          type: this.form.type,
          required: this.form.required,
          multiple: this.form.multiple,
          multipleMinCount: this.form.multipleMinCount,
          multipleMaxCount: this.form.multipleMaxCount,
          values: this.form.values,
          validate: this.form.validate,
          validateDescription: this.form.validateDescription,
          description: this.form.description,
          defaultValue: this.form.defaultValue,
          substitution: this.form.substitution,
          sort: this.form.sort,
          documents: this.form.documents,
          fileExtensions: this.form.fileExtensions,
          complexFields: this.form.complexFields,
        };
        console.log(this.formData);
        queryPost(`field/`, this.formData,
            {
              auth: {
                username: 'admin',
                password: 'sQwYySD1B8vVsqGcndiXtrumfQ'
                // eslint-disable-next-line no-return-assign
              },
              headers: {
                'Content-Type': 'application/json'
              }
            }
            // eslint-disable-next-line no-return-assign
        )
            .then(response => {
              this.res = response;
              this.isLoading = false;
            })
            // eslint-disable-next-line no-return-assign
            .catch(response => {
              this.res = response;
              this.isLoading = false;
            });
      }
    },
  }
}
</script>

<style scoped>

</style>